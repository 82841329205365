<template>
  <div :data-testid="`companiestable-listitem-${index}`">
    <div class="flex flex-row w-full py-1 px-2 items-center">
      <button class="mr-2" @click="toggle(index, !source.isSelected)" data-testid="toggle-checkbox">
        <Checkbox :active="source.isSelected" />
      </button>
      <div class="flex flex-grow w-tableIndex justify-center mr-3 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p
          class="text-md text-grey-dark-1 font-semibold"
          v-clamp="$screen.breakpoint.xlAndDown ? '30' : '60'"
        >
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md" v-clamp="$screen.breakpoint.xlAndDown ? '30' : '60'">
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/6">
        <p class="text-md text-grey-dark-2" v-clamp>
          {{ userRoles }}
        </p>
      </div>
      <div class="flex flex-grow justify-center text-md w-1/6">
        <p class="text-md" :class="statusColor">
          {{ status }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
export default {
  name: 'ListItem',
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function, default: () => null },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
    userRoles() {
      return this.source.roles
        .map((role) => (role.name === 'CompanyAdmin' ? 'Admin' : role.name))
        .join(', ');
    },
     status() {
      switch (this.source.status.toString()) {
        case '0':
          return 'Inactive';
        case '1':
          return 'Active';
        case '2':
          return 'Invited';
        case '3':
          return 'Not invited';
        default:
          break;
      }
    },
    statusColor() {
      switch (this.source.status.toString()) {
        case '0':
          return 'text-red';
        case '1':
          return 'text-green-2';
        case '2':
          return 'text-yellow-medium';
        case '3':
          return 'text-grey-medium-2';
        default:
          break;
      }
    },
  },
};
</script>
