<template>
  <div>
    <div class="flex flex-row items-end justify-between">
      <div class="flex items-center space-x-3">
        <InputField
          placeholder="Search by name or job title"
          label="Filter by"
          :onChange="setKeyword"
          leftIcon
          class="w-inputField"
          data-testid="filter"
        />
        <Select
          :options="this.statusOptions"
          :onSelect="setStatusFilter"
          :value="statusFilter"
          :preselectFirst="true"
          label="Status"
          objects
          class="ml-2 w-300 h-40"
        />
      <button
          class="flex items-center space-x-2 mt-2"
          @click="toggleShowOnlySelected"
        >
          <Checkbox :active="showOnlySelected" />
          <p class="text-mdh text-grey-dark-1">
            Show only selected users
          </p>
        </button>
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} users out of {{ totalUsersCount }}
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <div>
          <button
            class="flex flex-row w-tableIndex items-center focus:outline-none mr-3"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light">NO.</p>
          </button>
        </div>
        <div class="flex flex-grow w-1/4" data-testid="header-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('name', 0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/4" data-testid="header-2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('title', 1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6" data-testid="header-3">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">SYSTEM ROLE</p>
          </div>
        </div>
        <div class="flex flex-grow w-1/6 justify-center" data-testid="header-4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="showOnlySelected ? selectedUsers : mappedList"
          :data-component="itemComponent"
          data-testid="table"
          :extra-props="{ toggle: toggleItem }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import add from "@/assets/img/icons/add.svg";
import email from "@/assets/img/icons/email.svg";
import AddNewButton from "@/views/AdminAssessments/components/AddNewButton";

export default {
  name: "CompanyNotInvitedUsersTable",
  components: { VirtualList, AddNewButton },
  props: {
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    add,
    email,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    pageNr: 1,
    pageSz: 20,
    sorting: null,
    showOnlySelected: false,
    selectAll: false,
    statusFilter: null,
    statusOptions: [
        { label: "All Statuses", id: -1 },
        { label: "Active", id: 1 },
        { label: "Invited", id: 2 },
        { label: "Not Invited", id: 3 },
      ],
  }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      selectedUsersForInvite: (state) => state.people.selectedUsersForInvite,
      totalUsersCount: (state) => state.companies.totalUsersCount,
    }),
    selectedUsers() {
      return this.mappedList.filter((item) => item.isSelected);
    },
  },
  async mounted() {
    this.setSelectedUsersForInvite([]);
    await this.getCompany();
  },
  watch: {
    keyword() {
      this.updateList(true);
    },
    statusFilter() {
      this.updateList(true);
      this.selectAll = false;
      this.mappedList.map((item) => (item.isSelected = false));
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getCompanyDetails: "companies/getCompanyDetails",
      fetchCompanyUsers: "companies/fetchCompanyUsers",
      downloadTemplate: "utils/downloadTemplate",
      setSelectedUser: "companies/setSelectedUser",
      addUserToInvite: "people/addUserToInvite",
      removeUserFromInvite: "people/removeUserFromInvite",
      setSelectedUsersForInvite: "people/setSelectedUsersForInvite",
    }),
    async getCompany() {
      return await this.getCompanyDetails({ id: this.$route.params.companyId });
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    setStatusFilter(value) {
      this.statusFilter = value;
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.totalUsersCount / this.pageSz)) {
        this.pageNr++;
        this.updateList();
      }
    },
    mapSelected() {
      if (this.selectedUsersForInvite.length > 0) {
        this.selectedUsersForInvite.forEach((item) => {
          let index = this.mappedList.findIndex((el) => el.id === item.id);
          if (index > -1) {
            this.mappedList[index].isSelected = true;
          }
        });
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      const payload = {
        keyword: this.keyword,
        pageNumber: this.pageNr,
        pageSize: this.pageSz,
        sorting: this.sorting,
        allowInviteInactiveUsers: false,
        status: this.statusFilter.id,
      };
      this.fetchCompanyUsers({
        id: this.$route.params.companyId,
        payload,
      }).then((rsp) => {
        let map = rsp.map((item, idx) => ({
          ...item,
          isSelected: false,
          index: (this.pageNr - 1) * this.pageSz + idx,
        }));
        this.mappedList = [...this.mappedList, ...map];
        this.mapSelected();
        this.checkSelected();
      });
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    async sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "name":
          this.sorting = {name: this.rotationMatrix[0] == -1 ? 1 : 2}
          break;
        case "title":
          this.sorting = {jobTitle: this.rotationMatrix[1] == -1 ? 1 : 2}
          break;
        case "status":
          this.sorting = {status: this.rotationMatrix[2] == -1 ? 1 : 2}
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    checkSelected() {
      var count = 0;
      this.mappedList.forEach((element) => {
        if (element.isSelected) count++;
      });
      if (count === 0) this.$parent.disableNext = true;
      else this.$parent.disableNext = false;
    },
    toggleItem(index, value) {
      if (value) {
        this.addUserToInvite(this.mappedList[index]);
      } else {
        this.removeUserFromInvite(this.mappedList[index]);
      }
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
      this.checkSelected();
    },
    async fetchAllUsers(allSelected) {
      const payload = {
        keyword: this.keyword,
        pageNumber: 1,
        pageSize: this.totalUsersCount,
        sorting: this.sorting,
        allowInviteInactiveUsers: false,
        status: this.statusFilter.id,
      };
      await this.fetchCompanyUsers({
        id: this.$route.params.companyId,
        payload,
      }).then((rsp) => {
        let usersList = rsp.map((item, idx) => ({
          ...item,
          isSelected: allSelected,
          index: idx,
        }));
        this.mappedList = [...usersList];
        this.mapSelected();
        this.checkSelected();
      });
    },
    async toggleAll() {
      if (this.pageNr !== Math.ceil(this.totalUsersCount / this.pageSz)) {
        await this.fetchAllUsers(true);
      }
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
      this.checkSelected();
    },
    toggleShowOnlySelected() {
      this.showOnlySelected = !this.showOnlySelected;
    },
  },
};
</script>
<style lang="scss" scoped>
.new-company-btn {
  line-height: 1;
}
</style>
